import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import AppDatabase from "./db/app-db.service";
import { projectFilePath } from "./db/filesystem.service";

const getMapIconUrl = async (projectId: number, itemId: number) => {
  const path = projectFilePath(projectId, 'items', itemId);
  const result = await Filesystem.getUri({
    directory: Directory.Data,
    path
  });
  return Capacitor.convertFileSrc(result.uri);
}

export class MapIconService {

  private icons: Map<number, Set<string>> = new Map();

  private constructor() { }

  init() {
    AppDatabase.instance.events.addListener('syncDidUpdate', (projectId: number, _records: boolean, config: boolean) => {
      if (config) {
        this.clearIcons(projectId);
      }
    });
  }



  public static instance: MapIconService = new MapIconService();
  private map: mapboxgl.Map;

  public setMap(map: mapboxgl.Map) {
    this.map = map;

    map.on('styleimagemissing', async (e) => {
      const [projectId, itemId] = e.id.split('_').map(n => parseInt(n, 10));
      const key = `${projectId}_${itemId}`;
      if (!this.icons.has(projectId)) {
        this.icons.set(projectId, new Set());
      }
      const projectIcons = this.icons.get(projectId);

      // If we already have this icon, don't try to load it again
      if (projectIcons.has(key)) {
        return;
      }
      projectIcons.add(key);
      let url: string;
      if (Capacitor.isNativePlatform()) {
        url = await getMapIconUrl(projectId, itemId);
      } else {
        const item = await AppDatabase.instance.items.findById(itemId);
        url = item.icon;
      }
      this.applyIcon(key, url);
    });
  }

  private async applyIcon(key: string, url: string) {
    return new Promise<void>((resolve, reject) => {
      this.map.loadImage(url, (error, image) => {
        if (error) {
          reject(error);
        } else if (!this.map.hasImage(key)) {
          this.map.addImage(key, image);
        } else {
          this.map.removeImage(key);
          this.map.addImage(key, image);
        }
        resolve();
      });
    });
  }

  private clearIcons(projectId: number) {
    const projectIcons = this.icons.get(projectId);
    if (!projectIcons) {
      return;
    }
    for (const key of projectIcons) {
      this.map.removeImage(key);
    }
    this.icons.delete(projectId);
  }

}